import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="35.000000pt" height="35.000000pt" viewBox="0 0 35.000000 35.000000"
 preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
        <g transform="translate(0.000000,35.000000) scale(0.005795,-0.005892)"
        fill="#19BFB6" stroke="none">
            <path d="M3459 5450 c-9 -5 -328 -129 -710 -276 -382 -146 -695 -267 -696
            -268 -6 -5 300 -251 310 -250 11 1 1227 464 1236 471 3 2 -116 323 -123 330 0
            1 -8 -3 -17 -7z"/>
            <path d="M3340 4956 c-146 -56 -335 -130 -420 -163 -85 -33 -236 -91 -335
            -128 -99 -37 -184 -71 -189 -76 -11 -10 112 -319 128 -319 6 0 112 38 236 85
            l225 84 30 -33 c16 -18 40 -36 53 -40 22 -7 22 -10 22 -175 0 -145 -2 -171
            -17 -182 -32 -27 -44 -74 -30 -116 22 -70 126 -94 178 -42 18 18 35 19 274 19
            l255 0 0 -85 0 -85 65 0 65 0 0 -90 0 -91 78 3 77 3 0 390 0 390 -77 3 -78 3
            0 -91 0 -90 -65 0 -65 0 0 -75 0 -75 -253 0 c-324 0 -297 -19 -297 216 l0 170
            30 29 c34 32 54 91 44 129 -6 26 -13 23 366 168 58 23 107 42 108 44 3 2 -41
            122 -115 312 -3 6 -10 12 -17 11 -6 0 -130 -46 -276 -103z"/>
            <path d="M2108 4548 c-16 -5 -28 -13 -28 -18 0 -7 86 -234 125 -332 6 -15 11
            -15 43 -3 38 13 39 15 28 51 -6 19 1 24 51 44 32 13 59 24 61 25 1 1 -18 56
            -44 122 l-46 121 -61 -24 c-61 -24 -76 -22 -77 14 0 14 -14 14 -52 0z"/>
            <path d="M1421 3107 c-19 -6 -44 -25 -55 -40 -20 -28 -21 -39 -21 -356 0 -357
            2 -368 57 -397 20 -10 67 -14 175 -14 173 0 204 11 222 77 7 23 11 157 11 335
            0 320 -5 357 -53 388 -30 19 -287 25 -336 7z"/>
            <path d="M3793 3113 c-12 -2 -34 -18 -48 -34 -24 -30 -25 -34 -25 -189 l0
            -158 28 -29 c19 -21 46 -34 97 -47 175 -43 310 -148 398 -309 33 -60 56 -91
            73 -98 14 -5 99 -9 189 -9 156 0 165 1 190 23 54 47 48 104 -26 255 -125 256
            -341 445 -619 543 -49 18 -226 61 -232 58 -2 -1 -13 -4 -25 -6z"/>
            <path d="M3295 3081 c-423 -127 -707 -463 -789 -936 -21 -118 -21 -352 0 -470
            80 -462 355 -795 768 -929 128 -42 193 -30 222 41 22 51 19 288 -4 333 -17 36
            -26 41 -162 103 -133 60 -219 147 -299 302 -58 113 -75 200 -75 385 0 138 4
            178 22 245 56 207 183 365 358 445 38 18 85 40 104 49 58 27 70 65 70 219 0
            144 -10 187 -51 216 -30 20 -88 19 -164 -3z"/>
            <path d="M1413 2146 c-17 -8 -40 -27 -50 -42 -17 -26 -18 -67 -21 -624 -2
            -327 0 -614 3 -638 4 -27 17 -55 35 -75 l28 -32 144 -3 c161 -4 207 5 234 46
            18 25 19 62 22 647 3 671 2 680 -50 715 -33 21 -300 26 -345 6z"/>
            <path d="M4313 1570 c-12 -5 -42 -45 -70 -96 -89 -162 -219 -264 -387 -305
            -70 -18 -91 -27 -112 -52 -25 -30 -25 -31 -22 -195 l3 -164 29 -23 c41 -32 66
            -35 154 -15 152 34 274 85 402 169 182 119 334 317 409 530 22 64 15 104 -24
            139 -25 21 -34 22 -193 21 -92 0 -177 -4 -189 -9z"/>
        </g>
    </svg>

  );
}